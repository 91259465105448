// Contact Screen
export default function Contact() {
    return (
      <div class="flex justify-center items-center flex-col pb-40 pt-20 h-screen">
        <h2 class="text-6xl md:text-9xl font-bold mb-10 text-center">Let's Collaborate</h2>
        <a href = "mailto: iahbuenacosa@gmail.com" class="border-2 border-black rounded-full px-10 py-4 font-bold">Work with me</a>
        <ul class="w-72 md:w-full flex gap-10 mt-10 lg:mt-16 flex-wrap justify-center">
          <li>
            <a href="https://www.behance.net/iahmb" class="flex items-center justify-center flex-col" target="_blank" rel="noreferrer">
              <img src="/images/behance.svg" alt="icon" class="h-8 mb-2" />
              <p>Behance</p>
            </a>
          </li>
          <li>
            <a href="https://dribbble.com/iahmb" class="flex items-center justify-center flex-col" target="_blank" rel="noreferrer">
              <img src="/images/dribbble.svg" alt="icon" class="h-8 mb-2" />
              <p>Dribbble</p>
            </a>
          </li>
          <li>
            <a href="https://codepen.io/iahmb" class="flex items-center justify-center flex-col" target="_blank" rel="noreferrer">
              <img src="/images/codepen.svg" alt="icon" class="h-8 mb-2" />
              <p>Codepen</p>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/iah-buenacosa/" class="flex items-center justify-center flex-col" target="_blank" rel="noreferrer">
              <img src="/images/linkedin.svg" alt="icon" class="h-8 mb-2" />
              <p>LinkedIn</p>
            </a>
          </li>
          <li>
            <a href="mailto:iahbuenacosa@gmail.com" class="flex items-center justify-center flex-col" target="_blank" rel="noreferrer">
              <img src="/images/mail.svg" alt="icon" class="h-8 mb-2" />
              <p>Email</p>
            </a>
          </li>
        </ul>
      </div>
    );
  }