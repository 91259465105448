// About Screen
export default function About() {
  return (
      <div class="h-screen flex items-center justify-center flex-col md:flex-row text-center md:text-left">
        <img class="rounded-full w-72 h-72 md:w-80 md:h-80 lg:w-96 lg:h-96 mb-10 md:mb-0 md:mr-16 block" src="https://media.contra.com/image/upload/f_auto,c_limit,w_1200,q_auto/fjhte80qndtpy7mhdutf" alt="" />
        <div class="block w-96">
          <h2 class="text-6xl md:text-9xl font-bold mb-5">About</h2>
          <p class="mb-5">
          Designing visually appealing and user-friendly websites, mobile apps, and dashboards for diverse brands, achieving high-quality results in user engagement and brand visibility.
          </p>
          <p>Let's collaborate to create an exceptional online presence for your business ✦ </p>
        </div>
      </div>
  );
}