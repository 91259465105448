// Projects Screen with ProjectImage component

import ProjectImage from '../components/ProjectImage'

export default function Projects() {
    return (
      <div class="flex justify-center items-center flex-col pt-20 pb-40">
        <h2 class="text-6xl md:text-9xl font-bold mb-14">Works</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 grid-rows-3 gap-10">
          <ProjectImage description="Landing Page for Stellar" img="qqttizhi7wsjmpoqivff" projLink="187014607/Landing-Page-for-Stellar" />
          <ProjectImage description="Opulique E-commerce Dashboard" img="cztgb27njzm6yhadmxl0" projLink="186056411/Opulique-E-commerce-Dashboard" />
          <ProjectImage description="Dashboard Components" img="vdyns0rrcqoouvs4isfm" projLink="187014173/Dashboard-UI-Design-Components" />
          <ProjectImage description="Social Planning App" img="cp9vqtitb5ycqdlvuibl" projLink="168352523/Social-Planning-App" />
          <ProjectImage description="Social Planning App Case Study" img="vixee8qt0ybp7qowmbcw" projLink="168612061/Social-Planning-Mobile-App" />
          <ProjectImage description="Mobile App UI Components" img="dozxymqrtefo6rj7tto4" projLink="187014305/Mobile-App-UI-Components" />
          <ProjectImage description="Jetski Rental App" img="wxjyubn1atcmtvvz9psr" projLink="166270121/Jetski-Rental-App" />
          <ProjectImage description="Jetski Rental App UI Components" img="kcmaqccw4hz2ko2zpsfd" projLink="187013983/Jetski-Rental-App-UI-Components" />
          <ProjectImage description="Overview Dashboard with Bootstrap" img="lacetkjviipluour2us2" projLink="168352347/Overview-Dashboard-with-Bootstrap" />
          <ProjectImage description="ActiveMinds Sign Up Page" img="wugfebcpn3evnumxbksq" projLink="164177243/Sign-Up-Page" />
          <ProjectImage description="Crypto Portfolio Tracker App Design" img="kxw9lzsfvkzzovywjock" projLink="130653679/Crypto-Portfolio-Tracker-App-UI-Design" />
          <ProjectImage description="Kindle Paperwhite Simulator" img="a4vraoz70ghjlk2qlhze" projLink="191488923/Kindle-Paperwhite-Simulator" />
          <ProjectImage description="Greene Chairs Website" img="ym2uwbzaaf0p4kdaamwy" projLink="131098475/Responsive-Minimalist-Web-Design-(HTMLCSS-included)" />
          <ProjectImage description="Gribbin Real Estate Sign Up Form" img="lo70awrl18lzf7nu7ejj" projLink="137153871/Real-Estate-Sign-Up-Form" />
          <ProjectImage description="Signup form with CSS Paralax" img="wpgcy4cotkdazh3alnpe" projLink="136596087/Sign-Up-Form-Web-UI-Design" />
          <ProjectImage description="Stardew Valley Website Redesign" img="y2fttwkmdxnkhoacqkec" projLink="138112239/Stardew-Valley-Website-Redesign-Concept" />
          <ProjectImage description="SimpleForm Website Design" img="mttho05lmqgarxtzt2mg" projLink="191489237/Minimalist-Website-Design-for-SimpleForm" />
        </div>
      </div>
    );
  }
  